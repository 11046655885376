import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CarouselMobile from "../../components/Carousel/mobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"
import FermentacaoCounter from "../../components/TemperatureCounterFermentation"

// ASSETS
import FermentacaoVideo from "../../assets/videos/CamFermentacao/fermentacao.mp4"
import FermentacaoVideoPreview from "../../assets/videos/CamFermentacao/fermentacao-preview.mp4"
import FermentacaoDetailVideo from "../../assets/videos/CamFermentacao/fermentacao-detail-2.mp4"
import FermentacaoDetailVideoMobile from "../../assets/videos/CamFermentacao/mobile/fermentacao-video-detail-1-mobile.mp4"
import FermentacaoDetailVideoMobile2 from "../../assets/videos/CamFermentacao/mobile/fermentacao-video-detail-2-mobile.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const IndexPage = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('meta', { returnObjects: true })['title']}
        keywords={t('meta', { returnObjects: true })['keywords']}
        description={t('meta', { returnObjects: true })['description']}
        img={t('meta', { returnObjects: true })['image']}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        domain={location?.hostname}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={FermentacaoVideo}
        src={FermentacaoVideoPreview}
        poster="https://ramalhos.com/video_posters/CamFermentacao/fermentacao.jpg"
        width="100%"
        id="video-fermentacao"
        imgMobile={t('video', { returnObjects: true })['bgMobile']}
        fullString={t('videoHome', { returnObjects: true })['info']}
        toClick="#introduction"
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t('introduction', { returnObjects: true })}
      />

      {/* IF MOBILE */}
      {breakpoints.tl ? (
        <CarouselMobile
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-fermentacao-mobile"
          carouselContent={t('carousel', { returnObjects: true })}
        />
      ) : (
        //IF NOT
        <Carousel
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-fermentacao"
          carouselContent={t('carousel', { returnObjects: true })}
        />
      )}

      {/* DESKTOP  */}

      {!breakpoints.tl && (
        <>
          <FeatureLine
            id="feature-fermentacao"
            _title={t('feature', { returnObjects: true })['title5']}
            text={t('feature', { returnObjects: true })['text5']}
            image={t('feature', { returnObjects: true })['image5_1']}
          />
          <VideoDetail
            src={FermentacaoDetailVideo}
            poster="https://ramalhos.com/video_posters/CamFermentacao/fermentacao-detail-1.jpg"
            id="video-detail-fermentacao"
            _title={t('feature', { returnObjects: true })['title2']}
            text1={t('feature', { returnObjects: true })['text2']}
            positionCenter
          />
          <FeatureLine
            id="feature-fermentacao2"
            _title={t('feature', { returnObjects: true })['title3']}
            image={t('feature', { returnObjects: true })['image3_1']}
            positionCenter
            midBrightness
          />
          <FermentacaoCounter id="fermentacao-counter" info={t('massas', { returnObjects: true })} />
          <FeatureLine
            id="feature-line-fermentacao"
            _title={t('feature', { returnObjects: true })['title4']}
            height="unset"
            paddingTop="100px"
            noImage
            noBar
          />
        </>
      )}

      {/* --- */}

      {/* MOBILE */}

      {breakpoints.tl && (
        <>
          <VideoDetailMobile
            src={FermentacaoDetailVideoMobile}
            poster="https://ramalhos.com/video_posters/CamFermentacao/mobile/fermentacao-video-detail-1-mobile.jpg"
            id="video-detail-fermentacao-mobile"
            _title={t('feature', { returnObjects: true })['title5']}
            text={t('feature', { returnObjects: true })['text5']}
            positionAbsolute
            videoOpacity
          />

          <FeatureLineMobile
            id="first-line-mobile"
            _title={t('feature', { returnObjects: true })['title2']}
            image={t('feature', { returnObjects: true })['image1_1M']}
            absoluteCenter
            opacity="1"
          />

          <FeatureLineMobile
            id="second-line-mobile"
            _title={t('feature', { returnObjects: true })['title3']}
            absoluteCenter
            opacity="1"
          />

          <VideoDetailMobile
            src={FermentacaoDetailVideoMobile2}
            poster="https://ramalhos.com/video_posters/CamFermentacao/mobile/fermentacao-video-detail-2-mobile.jpg"
            id="video-detail-fermentacao-mobile-2"
            _title={t('feature', { returnObjects: true })['title1']}
            text={t('feature', { returnObjects: true })['text1']}
            positionAbsolute
            videoOpacity
          />
        </>
      )}

      {/* --- */}

      <SpecsTable
        id="spec-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        svgWidth="190px"
      />

        {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryFermentacaoPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "fermentacao", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
