import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { font, color } from '../../layout/global'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Fermentacao = ({ id, info }) => {
  const triggerRef = useRef(null),
        firstRef = useRef(null),
        secondRef = useRef(null),
        thirdRef = useRef(null),
        firstValueRef = useRef(null),
        secondValueRef = useRef(null),
        thirdValueRef = useRef(null),
        markerRef = useRef(null),
        leadingRef = useRef(null),
        secondContainerRef = useRef(null),
        thirdContainerRef = useRef(null)

  var firstInitial = { var: 20 }
  var secondInitial = { var: 3 }
  var thirdInitial = { var: 0 }

  useEffect(() => {
    let firstValue = document.querySelector(`#firstValueRef`)
    let secondValue = document.querySelector(`#secondValueRef`)
    let thirdValue = document.querySelector(`#thirdValueRef`)
    let leadingValue = document.querySelector(`#leadingRef`)
    let markerValue = document.querySelector(`#markerRef`)


    gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: triggerRef.current,
        toggleActions: 'play none none none',
        start: '35% center'
      }
    })
    .addLabel('StartNumbers', 2)
    .addLabel('StartNumbers2', 5)
    .fromTo([firstRef.current, secondRef.current, thirdRef.current], {opacity: 0}, {opacity: 0.2, stagger: 0.3, duration: 0.5}) // Fazer aparecer os textos
    .to(firstRef.current, {opacity: 1, duration: 0.3}) // Primeiro texto: Highlighted
    .fromTo(secondContainerRef.current, {opacity: 0}, {opacity: 1, duration: 1}, '>-0.3')
    .to(firstInitial, { var: 24, duration: 1, onUpdate: () => {firstValue.innerHTML = (firstInitial.var).toFixed()}, onComplete: () => {leadingValue.innerHTML = '0'}, ease: 'linear'}, 'StartNumbers')
    .set(firstInitial, { var: 0 })
    .to(firstInitial, { var: 2, duration: 1, onUpdate: () => {firstValue.innerHTML = (firstInitial.var).toFixed()}, ease: 'linear'}, 'StartNumbers+=1')
    .to(secondInitial, { var: 0, duration: 1, onUpdate: () => {secondValue.innerHTML = (secondInitial.var).toFixed()}, ease: 'linear', }, 'StartNumbers')
    .to(secondInitial, { var: 10, duration: 1, onUpdate: () => {secondValue.innerHTML = (secondInitial.var).toFixed()}, onStart: () => {markerValue.innerHTML = '+'}, ease: 'linear' }, 'StartNumbers+=1')
    .to(thirdInitial, { var: 80, duration: 2, onUpdate: () => {thirdValue.innerHTML = (thirdInitial.var).toFixed()}, ease: 'linear', }, 'StartNumbers')
    .to(firstRef.current, {opacity: 0.2, duration: 1}, '>+1') // Primeiro texto: Darkened
    .to(secondRef.current, {opacity: 1, duration: 0.3}, '<') // Segundo texto: Highlighted
    .to(firstInitial, {var: 6, duration: 2, onUpdate: () => {firstValue.innerHTML = (firstInitial.var).toFixed()}, ease: 'linear'}, 'StartNumbers2')
    .to(secondInitial, { var: 25, duration: 2, onUpdate: () => {secondValue.innerHTML = (secondInitial.var).toFixed()}, ease: 'linear', }, 'StartNumbers2')
    .to(secondRef.current, {opacity: 0.2, duration: 1}, '>+1') // Segundo texto: Darkened
    .to(thirdRef.current, {opacity: 1, duration: 0.3}, '<') // Terceiro texto: Highlighted
    .to(secondContainerRef.current, {opacity: 0, duration: 1}, '<')
    .fromTo(thirdContainerRef.current, {opacity: 0}, {opacity: 1, duration: 1}, '<+0.5')

  }, [firstInitial, secondInitial, thirdInitial])
  
  return (
  <Wrapper ref={triggerRef} id={id}>
    <div id="container">
      <div className='first' ref={firstRef}><h3 dangerouslySetInnerHTML={{ __html: info.text1 }}></h3></div>
      <div className='second' ref={secondRef}><h3 dangerouslySetInnerHTML={{ __html: info.text2 }}></h3></div>
      <div className='third' ref={thirdRef}><h3 dangerouslySetInnerHTML={{ __html: info.text3 }}></h3></div>
    </div>
    <div id="containerW">
      <div id="container2" ref={secondContainerRef}>
        <div className='value first-value'><h3><span id="leadingRef" ref={leadingRef}></span><span id="firstValueRef" ref={firstValueRef}>20</span><span>H</span></h3></div>
        <div className='value second-value'><h3><span id="markerRef" ref={markerRef}>-</span><span id="secondValueRef" ref={secondValueRef}>3</span><span>ºC</span></h3></div>
        <div className='value third-value'><h3><span id="thirdValueRef" ref={thirdValueRef}>0</span><span>%HR</span></h3></div>
      </div>
      <div id="container3" ref={thirdContainerRef}>
        <div><h3><span dangerouslySetInnerHTML={{ __html: info.text4 }}></span></h3></div>
      </div>
    </div>
  </Wrapper>
  )
}

export default Fermentacao

const Wrapper = styled.section`
  position: relative;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #container {
    width:100%;
    text-align:center;
    display: grid;
    grid-template-areas: "first second third";
    padding: 0 10%;
    margin-bottom: 125px;

    .first, .second, .third{
      opacity:0.2;
    }

    .first{
      grid-area:first;
    }

    .second{
      grid-area:second;
    }

    .third{
      grid-area:third;
    }
    h3 {
      ${font.robotoBold};
      font-size: 2.5rem;
      color: ${color.white};
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    span {
      color: ${color.red};
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      font-size:5rem;
    }
  }

  #containerW {
    position: relative;
    width: 100%;
  }
  #container2 {
    position: absolute;
    width: 100%;
    display: grid;
    grid-template-areas: "first-value second-value third-value";
    text-align: center;
    padding: 0 25%;

    .first-value{
      grid-area:first-value;
    }

    .second-value{
      grid-area:second-value;
    }

    .third-value{
      grid-area:third-value;
    }

    .value {
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      min-width: 250px;
    }
    h3 {
      ${font.robotoBold};
      font-size: 2.5rem;
      color: ${color.white};
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    span{
      color: ${color.red};
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      font-size: 5rem;
    }
  }

  #container3 {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    h3 {
      ${font.robotoBold};
      font-size: 2.5rem;
      color: ${color.white};
      letter-spacing: 2px;
    }
    span {
      color: ${color.red};
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      font-size:5rem;
    }
  }
`